<template>
  <v-form class="mt-2">
    <v-row>
      <v-col>
        <tfoms-finance
          v-if="data && data.SanctionValues"
          title="СУММА САНКЦИЙ МЭК"
          :values="[
            {
              value: data.SanctionTotalValue,
              label: this.provideUnits.rub.text,
            },
          ]"
          :data="
            getMonthValues(
              data.SanctionValues.map((e) => ({
                value: [e.SanctionValue, 0],
                id: e.Id,
              }))
            )
          "
          :labels="labels"
          only-sanction
          :sub-title="subTitlePeriod"
        >
        </tfoms-finance
      ></v-col>
      <v-col>
        <tfoms-progress
          title="СУММА санкций МЭК (в разрезе кодов)"
          icon="mdi-currency-rub"
          factColor="var(--v-error-base)"
          :data="finances"
          :type="1"
          :value="selectCode"
          backgroundColor="var(--v-error-lighten1)"
          factText="Штрафы"
          planText="Не оплата"
          :labels="labels"
          only-no-payment
          :sub-title="subTitlePeriod"
          @click="onclick"
          :values="[
            {
              label: this.provideUnits.rub.text,
            },
          ]"
        ></tfoms-progress>
      </v-col>
    </v-row>
    <tfoms-condition-table
      v-if="data && data.Children"
      :data="
        data.Children.map((e) => ({
          plan: e.NonPaymentValue,
          fact: e.FineValue,
          percent: e.SanctionValue,
          Name: e.Name,
          id: e.Id,
          Codes: e.Codes,
        }))
      "
      :labels="labelsCode"
      :subTitle="subTitlePeriod"
      class="mt-5"
      color="success"
      isNoPayment
      :values="[
        {
          label: this.provideUnits.rub.text,
        },
      ]"
    >
    </tfoms-condition-table>
    <v-row class="mt-5">
      <v-col
        ><tfoms-partition-table
          v-if="data && data.Divisions"
          v-model="selectEmployee"
          :data="data.Divisions"
          :labels="labelsCode"
          :type="5"
          icon="mdi-currency-rub"
          iconColor="var(--v-error-lighten1)"
          :sub-title="subTitlePeriod"
          :values="[
            {
              label: this.provideUnits.rub.text,
            },
          ]"
        >
        </tfoms-partition-table
      ></v-col>
      <v-col
        ><tfoms-employee-table
          v-if="data && data.Divisions"
          :selectPartiton="selectEmployee"
          :data="data.Divisions"
          :labels="labelsCode"
          :type="5"
          icon="mdi-currency-rub"
          iconColor="var(--v-error-lighten1)"
          :items="data.Employees"
          :sub-title="subTitlePeriod"
          :values="[
            {
              label: this.provideUnits.rub.text,
            },
          ]"
          ref="tfomsEmployeeTable"
        >
        </tfoms-employee-table
      ></v-col>
    </v-row>
  </v-form>
</template>
<script>
import DataHelper from "../../../../../utils/DataHelper";
import tfomsTab from "../../mixins/tfomsTab";
import TFormService from "@/services/TFormService";

export default {
  name: "view-AnalysisTform-more5",

  components: {
    // tfomsProgress,
    tfomsProgress: () => import("../../components/tfomsProgress.vue"),
    tfomsFinance: () => import("../../components/tfomsFinance.vue"),
    tfomsConditionTable: () => import("../../components/tfomsConditionTable"),
    tfomsPartitionTable: () => import("../../components/tfomsPartitionTable"),
    tfomsEmployeeTable: () => import("../../components/tfomsEmployeeTable.vue"),
  },
  mixins: [tfomsTab],
  props: {
    data: { type: Object, default: null },
    labels: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      selectEmployee: null,
      selectCode: null,
    };
  },
  computed: {
    labelsCode() {
      if (this.selectCode) {
        return [...this.labels, this.selectCode.Name];
      } else {
        return this.labels;
      }
    },
    finances() {
      if (!this.data.ReasonRefusalPayDatas) return [];

      const maxFine = Math.max(
        ...this.data.ReasonRefusalPayDatas.map((e) => e.FineValue)
      );
      const maxNonPayment = Math.max(
        ...this.data.ReasonRefusalPayDatas.map((e) => e.NonPaymentValue)
      );
      const func = (data) => {
        if (!data) return;
        const ret = [];

        for (let i = 0; i < data.length; i++) {
          const item = data[i];

          ret.push({
            Name: item.Name,

            Id: item.Id,
            Children: func(item.Children),
            Unit: this.rub.text,
            MaxValues: [maxNonPayment, maxFine, maxNonPayment + maxFine],
            Values: [item.NonPaymentValue, item.FineValue],
            Value1: item.NonPaymentValue,
            Value2: item.FineValue,
            Value3: parseFloat(
              (item.FineValue + item.NonPaymentValue).toFixed(2)
            ),
            Labels: ["Неоплата", "Штраф", "Сумма"],
          });
        }
        return ret;
      };
      return func(this.data.ReasonRefusalPayDatas);
    },
  },
  watch: {
    "detailParams.params": {
      handler(val) {
        if (val.reasonId === null) this.selectCode = null;
      },
    },
  },
  methods: {
    normolize(data) {
      DataHelper.treeListTraverse(data.Divisions, (e) => {
        e.FinancialValue = this.getRub(e.FinancialValue);
        e.FineValue = this.getRub(e.FineValue);
        e.NonPaymentValue = this.getRub(e.NonPaymentValue);
        e.VolumeValue = this.getUnit(e.VolumeValue);
      });

      for (let i = 0; i < data.Children.length; i++) {
        const item = data.Children[i];
        item.VolumeValue = this.getUnit(item.VolumeValue);
        item.VolumePlanValue = this.getUnit(item.VolumePlanValue);
        item.FinancialPlanValue = this.getRub(item.FinancialPlanValue);
        item.FinancialValue = this.getRub(item.FinancialValue);

        item.NonPaymentValue = this.getRub(item.NonPaymentValue);
        item.FineValue = this.getRub(item.FineValue);
        item.SanctionValue = this.getRub(item.SanctionValue);
      }
    },
    async update(val) {
      const { data } = await TFormService.tfoms1.getReasonDetail({
        ...this.queryParams.params,
        ...this.detailParams.params,
      });

      this.normolize(data);

      this.data.Children = data.Children;
      this.data.Divisions = data.Divisions;

      this.$refs.tfomsEmployeeTable?.init();
    },
    onclick(val) {
      this.update(val);
      this.selectCode = val;
    },
  },
};
</script>
